import { AfterViewInit, Component } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';

declare var googleTranslate:any;
declare var languages:any;
declare var google:any;

@Component({
  selector: 'translate-language-select',
  templateUrl: './translate-language-select.component.html',
  styleUrls: ['./translate-language-select.component.scss']
})
export class TranslateLanguageSelectComponent implements AfterViewInit{

  public data:any = [];
  public configuration:any = null;
  public selected = null;
  public comboTranslate:any = null;
  public lang:any = '';
  public width:any = null;
  public showMenu:any = false;

  constructor(
    private storage: StorageService,
    private dataService: DataService,
    private app: AppService
  ){

    this.data = [{
      title: "Português",
      lang: "pt",
      icon: "/images/icons/lang/pt.svg"
    },{
      title: "Inglês",
      lang: "en",
      icon: "/images/icons/lang/en.svg"
    },{
      title: "Espanhol",
      lang: "es",
      icon: "/images/icons/lang/es.svg"
    }];

  }  
  /**
   * 
   * Select
   * 
   */
  select(){

    this.selected = this.dataService.getLanguage(); 

    if(this.selected == null){
      if(this.data.length > 0){

        this.selected = this.data[0];

      }
    }

    this.lang = this.selected.lang;
    this.changeCombo(this.selected.lang);       
   

  }
  /**
   * 
   * Change combo
   * 
   * @param lang 
   * 
   */
  changeCombo(lang:any){ 

    //if(lang != this.selected.lang){
      
      this.comboTranslate = document.getElementById("google_translate_element").querySelector(".goog-te-combo");
      console.log(this.comboTranslate);
      this.comboTranslate.value = lang;
      this.changeEvent(this.comboTranslate);
    
    //}

  }
  /**
   * 
   * Click
   * 
   */
  _click(lang:any){

    this.dataService.setLanguage(lang); 
    this.selected = lang;

    this.app.updateLang.emit(this.selected);

    var url = window.location.href;   

    var dataUrl = url.split("?");   
    
    if(dataUrl.length == 1){
      url += '?lang='+lang.lang;    
    }else{
      url = dataUrl[0]+"?lang="+lang.lang;
    }
    this.lang = lang.lang;  
    this.changeCombo(this.lang); 
    
    this.showMenu = false;

    //window.location.href = url; 
       

  }
  /**
   * 
   * Change
   * 
   * @param el 
   * 
   */
  changeEvent(el) {
    if (el.fireEvent) {
        el.fireEvent('onchange');
    } else {
        var evObj = document.createEvent("HTMLEvents");

        evObj.initEvent("change", false, true);
        el.dispatchEvent(evObj);
    }
  }
  /**
   * 
   * On Resize
   * 
   */
  onResize(){

    this.width = window.innerWidth;

  }
  /**
   * 
   * Show
   * 
   */
  _show(){

    this.showMenu = this.showMenu ? false : true; 
    
  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{ 
    this.configuration = this.dataService.getConfiguracao();
    this.width = window.innerWidth;
  }
  /**
   * 
   * After View
   * 
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.select();
    },1000);
  }
  
}
