<div class="translate-language-select" (window:resize)="onResize()">
      <div class="content home" *ngIf="width > 700">
          <div class="selected" *ngIf="selected != null">
              <div class="flag">
                  <img [src]="selected.icon" [alt]="selected.title" width="25px" height="25px" /> 
              </div>
              <div class="icon"> 
                  <i class="fa-solid fa-chevron-down"></i>
              </div>
          </div>
          <div class="content-list">
              <div class="item" *ngFor="let l of data" (click)="_click(l)" [ngClass]="{'hidden': lang == l.lang}">
                  <div class="icon">
                      <img [src]="l.icon" [alt]="l.title" width="25px" height="25px" /> 
                  </div>
                  <div class="text">  
                      <span>{{l.title}}</span>
                  </div>
              </div>
          </div>
      </div>
      <div class="content mobile" *ngIf="width <= 700">
          <div class="selected" *ngIf="selected != null" (click)="_show()"> 
              <div class="flag">
                  <img [src]="selected.icon" [alt]="selected.title" width="25px" height="25px" /> 
              </div>
              <div class="icon"> 
                  <i class="fa-solid fa-chevron-down"></i>
              </div>  
          </div>
          <div class="content-list" [ngClass]="{'show': showMenu}">
              <div class="item" *ngFor="let l of data" (click)="_click(l)" [ngClass]="{'hidden': lang == l.lang}">
                  <div class="icon">
                      <img [src]="l.icon" [alt]="l.title" width="25px" height="25px" /> 
                  </div>
                  <div class="text">  
                      <span>{{l.title}}</span>
                  </div>
              </div>
          </div>
      </div>
</div>