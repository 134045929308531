<header class="header template-default">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-5 col-logo">
                    <modulo posicao="logo"></modulo>  
                </div>
                <div class="col-md-9 col-center col-7">    
                    <div class="content-action">
                        <div class="item item-cart">  
                            <btn-cart translate="no"></btn-cart>   
                        </div>
                        <div class="item item-user"> 
                            <btn-usuario-login translate="no" [usuarioService]="usuarioService"></btn-usuario-login>
                        </div> 
                        <div class="item item-menu">   
                            <a translate="no" href="javascript::void();" title="Menu" (click)="_showMenu()">
                                <i class="material-icons">menu</i>
                                <span>Menu</span>
                            </a> 
                        </div>
                        <div class="item-translate">
                            <translate-language-select></translate-language-select>
                        </div>
                    </div>    
                </div>
            </div>    
        </div>     
    </div>    
</header>  
<sidebar-menu [showMenu]="showMenu"></sidebar-menu>   